import { Box, Container, Image, Center } from '@chakra-ui/react';
import { useMediaQuery } from "@chakra-ui/react"

  
export default function Menu() {
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const [isStandard] = useMediaQuery("(max-width: 2559px)");

  return (
    <Box>
      <Center>
        <Image src="/files/Menu-Lg.png"></Image> 
      </Center>
    </Box>
  );
}