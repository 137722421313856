import {Stack, Heading, Text, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Container, Divider} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { useMediaQuery } from "@chakra-ui/react"

export default function FAQ() {
    const [data, setData] = useState([]);
    const [isMobile] = useMediaQuery("(max-width: 768px)");

    const getData=()=>{
    fetch('/Data/FAQ.json',{
        headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
        }
    }
    ).then(function(response){
        return response.json();
    }).then(function(myJson) {
        setData(myJson.FAQ)
    });
    }

    useEffect(()=>{
        getData()
    },[])

    return(    
        <Stack spacing={6}>
            <Container maxW='3xl'>
              <Divider width={isMobile ? "100%" : "100%"} mt="5%" mb="10px" bg="#b36125" padding="1px"/>
                <Heading textAlign={'center'} size={isMobile ? "xl" : "3xl"}>Frequently Asked Questions</Heading>
              <Divider width={isMobile ? "100%" : "100%"} mt="2%" mb="10px" bg="#b36125" padding="1px"/>
            </Container>
            {data.map(({ question, answer }, index) => (  
                <Accordion key={index} allowToggle>
                    <AccordionItem>
                        <AccordionButton>
                            <Heading as="span" fontSize={isMobile ? '1xl' : '2xl'} flex='1' textAlign='left'>
                                {question}
                            </Heading>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4}>
                            <Text fontSize={isMobile ? "sm" : "xl"}>
                                {answer} {index == 2 ? <a href="https://nicholeandmatt.com/#/RSVP" target="_blank" style={{ color: 'blue' }}>here.</a> : null} 
                                {index == 4 ? <a href="https://nicholeandmatt.com/#/dresscode" target="_blank" style={{ color: 'blue' }}>attire.</a> : null}
                            </Text>
                            
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>
            ))};
        </Stack>
    )
}