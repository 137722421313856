import React from 'react';
import {
  Box,
  IconButton,
  useBreakpointValue,
  Stack,
  Heading,
  Text,
  Container,
  VStack,
  HStack,
  Spacer,
  Flex,
  Center
} from '@chakra-ui/react';
import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi';
import Slider from 'react-slick';
import Story from '../Story/Story'
import { useMediaQuery } from "@chakra-ui/react"
import { useState, useEffect } from 'react';

const settings = {
  dots: true,
  arrows: false,
  fade: true,
  infinite: true,
  autoplay: true,
  speed: 500,
  autoplaySpeed: 2000,
  slidesToShow: 1,
  slidesToScroll: 1,
};


export default function Title() {
  const [slider, setSlider] = React.useState<Slider | null>(null);
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const top = useBreakpointValue({ base: '90%', md: '50%' });
  const side = useBreakpointValue({ base: '30%', md: '40px' });

  const [countdown, setCountdown] = useState({ days: 0, hours: 0, minutes: 0 });

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     const targetDate = new Date('September 15, 2023 17:30:00');
  //     const currentDate = new Date();

  //     const timeDifference = Number(targetDate) - Number(currentDate);

  //     if (timeDifference > 0) {
  //       const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  //       const hours = Math.floor(
  //         (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  //       );
  //       const minutes = Math.floor(
  //         (timeDifference % (1000 * 60 * 60)) / (1000 * 60) + 1
  //       );

  //       setCountdown({ days, hours, minutes });
  //     }
  //   }, 1000);

  // }, []);
  const cards = [
    {
      title: 'Nichole & Matt',
      text1:
        "September 15",
      text2: " 2023",
      image:
        '/files/Signal7.png',
    },
    {
      title: 'Nichole & Matt',
      text1:
        "September 15",
      text2: " 2023",
      image:
        '/files/Signal6.png',
    },
    {
      title: 'Nichole & Matt',
      text1:
        "September 15",
      text2: " 2023",
      image:
        '/files/Signal9.png',
    },
    {
      title: 'Nichole & Matt',
      text1:
        "September 15",
      text2: " 2023",
      image:
        '/files/Signal3.png',
    }
  ];

  return (
    <Box>
        <Box
          position={'relative'}
          width={'full'}
          mt={isMobile ? '-10px' : '0'}
        >
        <link
          rel="stylesheet"
          type="text/css"
          charSet="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
        <IconButton
          hidden={isMobile}
          aria-label="left-arrow"
          variant="ghost"
          position="absolute"
          left={side}
          top={top}
          transform={'translate(0%, -40%)'}
          zIndex={2}
          onClick={() => slider?.slickPrev()}>
          <BiLeftArrowAlt size="40px" />
        </IconButton>
        <IconButton
          hidden={isMobile}
          aria-label="right-arrow"
          variant="ghost"
          position="absolute"
          right={side}
          top={top}
          transform={'translate(0%, -40%)'}
          zIndex={2}
          onClick={() => slider?.slickNext()}>
          <BiRightArrowAlt size="40px" />
        </IconButton>
        <Slider {...settings} ref={(slider) => setSlider(slider)}>
          {cards.map((card, index) => (
            <Box key={index} height={isMobile ? '300px' : '3xl'} position="relative">
            <div style={{position: 'absolute', top: isMobile ? 0 : 0, left: 0, right: 0, bottom: 0}}>
              <img
                src={card.image}
                alt={card.title}
                style={{ height: '100%', width: '100%', objectFit: 'cover' }}
              />
              <Container size="container.lg" position="relative">
                <Stack
                  spacing={6}
                  position="relative"
                  transform="translate(0, -300%)"
                >
                  <Box
                    position="absolute"
                    left="50%" 
                    width={isMobile ? "80%" : "100%"}
                    transform={isMobile ? "translate(-50%, -125%)" : "translate(-50%, -150%)"}
                    backdropFilter="blur(10px)"
                    borderRadius="md"
                    p={isMobile ? 3 : 4}
                    textAlign="center"
                  >
                    <Heading size={isMobile ? "sm" : "xl"} mb={2} color="white">
                      {card.title}
                    </Heading>
                    <Text fontSize={isMobile ? "sm" : "2xl"} color="white">{card.text1}<sup>th</sup>{card.text2}</Text>
                    {/* <VStack>
                      <HStack>
                        <Text fontSize={isMobile? "md" : "xl"} color="white">{countdown.days}</Text> <Text fontSize={isMobile? "md" : "xl"} color="white">{countdown.hours}</Text> <Text fontSize={isMobile? "md" : "xl"} color="white">{countdown.minutes}</Text>
                      </HStack>
                      <HStack>
                        <Text fontSize={isMobile? "md" : "xl"} color="white">Days</Text> <Text fontSize={isMobile? "md" : "xl"} color="white">Hours</Text>  <Text fontSize={isMobile? "md" : "xl"} color="white">Minutes</Text> 
                      </HStack>
                    </VStack> */}
                    <Center mt={isMobile ? "5px" : "10px" }  ml="15px">
                      <HStack maxWidth="120%">
                        <VStack>
                          <Flex align="center">
                            <Heading fontSize={isMobile ? "xs" : "3xl"} color="white">
                              {countdown.days}
                            </Heading>
                          </Flex>
                          <Heading fontSize={isMobile ? "xs" : "md"} color="white">
                            days
                          </Heading>
                        </VStack>
                        <Spacer/>
                        <VStack>
                          <Flex align="center">
                            <Heading fontSize={isMobile ? "xs" : "3xl"} color="white">
                              {countdown.hours}
                            </Heading>
                          </Flex>
                          <Heading fontSize={isMobile ? "xs" : "md"} color="white">
                            hours
                          </Heading>
                        </VStack>
                        <Spacer/>
                        <VStack>
                          <Flex align="center">
                            <Heading fontSize={isMobile ? "xs" : "3xl"} color="white">
                              {countdown.minutes}
                            </Heading>
                          </Flex>
                          <Heading fontSize={isMobile ? "xs" : "md"} color="white">
                            minutes
                          </Heading>
                        </VStack>
                      </HStack>
                    </Center>                   
                  </Box>
                </Stack>      
              </Container>
            </div>
          </Box>
          ))}
        </Slider>
      </Box>
      <Story/>
    </Box>
  );
}