import {Stack, Heading, Box, SimpleGrid, Container, Center, Text, Divider, HStack, VStack} from '@chakra-ui/react';
import { useMediaQuery } from "@chakra-ui/react"
import '../../css/polaroid.css'
export default function Story() {
    const [isMobile] = useMediaQuery("(max-width: 768px)");
    return(    
        <Box mt={isMobile ? "15%" : "5%"}>
            <Stack spacing={6}>
                <Center>
                    <Container maxW='3xl'>
                        <Divider width={isMobile ? "110%" : "100%"} mt="2%" mb="10px" bg="#b36125" padding="1px"/>
                            <Heading textAlign={'center'} size={isMobile ? "2xl" : "3xl"}>Our Story</Heading>
                        <Divider width={isMobile ? "110%" : "100%"} mt="2%" mb="10px" bg="#b36125" padding="1px"/>
                    </Container>
                </Center>
                <Center>
                    <Text as='h3' fontSize={isMobile ? 'xl' : '5xl'}  mb={"4%"}>
                        How We Met: A Modern Day Romance
                    </Text>
                </Center>
                <VStack minH={"fit-content"}> 
                    <HStack mb={isMobile ? "10%" : "25%"}>
                        <Text as='h5' width={isMobile ? "135%" : "145%"} fontSize={isMobile ? 'sm' : '2xl'} noOfLines={isMobile ? 15 : 4} >
                            Our story began in Hamilton in 2014. Tinder was all the buzz and we both just so happened to swipe right.
                            After some casual in app conversation, we exchanged numbers and decided to meet in person shortly after.
                        </Text>
                            <div className="picture-container">
                                <div className="polaroid image2" polaroid-caption="">
                                    <img src="/files/Tinder.png" alt="Matched" />
                                </div>
                            </div>
                    </HStack>
                    <HStack>
                        <div className="picture-container">
                            <div className="polaroid image1" polaroid-caption="">
                                <img src="/files/Dqpic.png" alt="DQ" />
                            </div>
                        </div>
                        <Text as='h5' fontSize={isMobile ? 'sm' : '2xl'} width={isMobile ? "145%" : "120%"}  ml={isMobile ? 10 : ""}> 
                            If you know anything about either one of us, it won't surprise you to hear that our first date was at Dairy Queen on Main Street in Hamilton. 
                            We recently found out when driving in the area that the DQ has been torn down, but our love remains. Despite driving the wrong way on a one-way street (welcome to Hamilton) and nearly killing us both, we kept in touch. 
                        </Text>
                    </HStack>
                    <Box minH={isMobile ? "200px" : "400px"}>
                            <div className="picture-container">
                                <div className="polaroid-shmedium" polaroid-caption="✍🏻:Alyssa Bugarsky">
                                    <img src="/files/Engagement.png" alt="Engaged" />
                                </div>
                            </div>
                    </Box>
                    <HStack>
                        <Text as='h5' width={isMobile ? "100%" : "200%"} fontSize={isMobile ? 'sm' : '2xl'} noOfLines={45} ml={isMobile ? -5 : 0} marginTop={isMobile ? "20px" : "250px"}>
                            In 2016 we moved to Etobicoke for Nichole’s internship and lived there for 16 months. In June of 2021 we moved to Kitchener together and in July Matt proposed.
                            Since then, we adopted a fur baby named Ellie in August of 2021. 
                            <br/><br/>Currently, we are in wedding planning *bliss*.  
                        </Text>                       
                    </HStack>
                    <Box minH={isMobile ? "200px" : "400px"}>
                            <div className="picture-container">
                                <div className="polaroid-small" polaroid-caption="Eleanor Purrsevelt">
                                    <img src="/files/Brenda.png" alt="Brenda" />
                                </div>
                            </div>
                    </Box>
                </VStack>       
            </Stack>
        </Box>     
    )
}


