import {Box,Flex,HStack} from '@chakra-ui/react';
import { HashLink } from 'react-router-hash-link';
import { useMediaQuery } from "@chakra-ui/react"

const Links = ['/', 'Photos', 'Details', 'Dresscode', 'Menu', 'FAQ'];
const Display = ['Home', 'Photos', 'Details', 'Attire', 'Menu', 'FAQ'];

export function Navigation() {
  const [isMobile] = useMediaQuery("(max-width: 768px)")
  return (
    <Box px={4}>
      <Flex h={16} alignItems={'center'} justifyContent={"center"}>
        <HStack spacing={isMobile ? 2 : 24} alignItems={'center'}>
          <HStack as={'nav'} spacing={isMobile ? "20px" : 12}>
            {Links.map((link,index) => (
              <Box key={index} _hover={{color:'#d6beab'}} fontSize={isMobile ? "sm" : "xl"}>
                <HashLink smooth to={link} key={link}>{Display[index]}</HashLink>
              </Box>               
            ))}
          </HStack>
        </HStack>
      </Flex>
    </Box>
  );
}