import { Navigation } from "../Components/Nav/Navigation"
import Title from './Title/Title'
import Footer from '../Components/Footer/Footer'
import Photos from '../Components/Photos/Photos'
import Details from '../Components/Details/Details'
import DressCode from '../Components/DressCode/DressCode'
import Menu from '../Components/Menu/Menu'
import FAQ from '../Components/FAQ/FAQ'
import { BrowserRouter, HashRouter, Route, Routes } from "react-router-dom";
export default function Home() {
    return (
        <HashRouter>
            <Navigation/>
            <Routes>
                <Route path="/" element={<Title />} />
                <Route path="/Photos" element={<Photos />} />
                <Route path="/Details" element={<Details />} />
                <Route path="/DressCode" element={<DressCode />} />
                <Route path="/Menu" element={<Menu />} />
                <Route path="/FAQ" element={<FAQ />} />
            </Routes>   
            <Footer/>   
        </HashRouter> 
    )
}