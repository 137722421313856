import {Box, Text, Center, Container, Heading, Divider} from '@chakra-ui/react';
import { useMediaQuery } from "@chakra-ui/react"

export default function DressCode() {
    const [isMobile] = useMediaQuery("(max-width: 768px)");

    return(  
        <Box>
            <Container maxW='3xl'>
                <Divider width={isMobile ? "100%" : "100%"} mt="5%" mb="10px" bg="#b36125" padding="1px"/>
                    <Heading textAlign={'center'} size={isMobile ? "2xl" : "3xl"}>Dress Code</Heading>
                <Divider width={isMobile ? "100%" : "100%"} mt="2%" mb="10px" bg="#b36125" padding="1px"/>
            </Container>
            <Center>
                <Box maxWidth={isMobile ? "100%" : "80%"} borderWidth="2px" borderRadius="lg" borderColor={"black"} p="4" bg="white" mt={isMobile ? 5 : 10}>
                    <Text fontWeight="bold" fontSize={isMobile ? "xl" : "3xl"} mb="2" color="blackAlpha.700">
                        Cocktail
                    </Text>
                    <Text mb="2" color="black">
                        [kok - teyl] <i>noun</i>
                    </Text>
                    <Text color="blackAlpha.700" fontSize={isMobile ? "lg" : "2xl"}>
                        A small step above semi-formal, though not as formal as black-tie
                        optional or black-tie, cocktail attire is a popular dress code choice.
                        It's a balance between elegant and comfortable and typically more
                        formal than a day wedding but more casual than a night celebration.
                        Instead of a floor-length dress, women should opt for a tea-length,
                        knee-length, or midi dress. Men are required to wear a suit, regardless
                        of the wedding's setting.
                    </Text>
                </Box>
            </Center>       
        </Box>
        
    )
}