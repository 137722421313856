import { StepsStyleConfig } from 'chakra-ui-steps';
import { extendTheme, type ThemeConfig } from '@chakra-ui/react'
import { mode } from "@chakra-ui/theme-tools";

const config: ThemeConfig = {
  initialColorMode: 'light',
}


const theme = extendTheme({ 
  config,
  shadows: { outline: '0 0 0 3px #d6beab' },
  styles:{
    global: (props)=> ({
      '@keyframes animate': {
        '0%': {
          opacity: 0,
          transform: 'rotate(45deg) translate(-10px, -10px)',
        },
        '50%': {
          opacity: 1,
        },
        '100%': {
          opacity: 0,
          transform: 'rotate(45deg) translate(20px, 20px)',
        },
      },
      '@keyframes blink': {
        '0%': {
          opacity: 1 },
        '50%': {
          opacity: 0 },
        '100%': {
          opacity: 1 } 
        },
        '::selection':{
          color: '#d6beab'
        },
      body: {
        bg: mode("#d6beab", "#d6beab")(props),
        color: mode("#492318", "#492318")(props),
      },
      PinInputField: {
        bg: mode("#d6beab", "#d6beab")(props),
        color: mode("red", "green")(props),
      },
    })
  },
  fonts: {
    heading: 'playfair display sc, sans-serif',
    body: 'eb garamond, sans-serif',
  },
  components:{
  },
 })

export default theme