import {
  Box,
  Button,
  Divider,
  Heading,
  List,
  ListIcon,
  ListItem,
  Stack,
  Text,
  Container,
  AspectRatio,
  SimpleGrid,
  Grid,
  Center,
  HStack,
  Flex,
  VStack,
} from "@chakra-ui/react";
import { FaHotel } from "react-icons/fa";
import { GiPathDistance, GiSandsOfTime } from "react-icons/gi";
import { useMediaQuery } from "@chakra-ui/react";

const options = [
  [
    {
      id: 1,
      name: "Four Points by Sheraton",
      website:
        "https://www.marriott.com/en-us/hotels/ykffp-four-points-by-sheraton-cambridge-kitchener-ontario/overview/?scid=f2ae0541-1279-4f24-b197-a979c79310b0",
      address: "Address: 210 Preston Pkwy, Cambridge, ON N3H 5N1",
      distance: "Distance from Venue: 5km",
      drive: "Approximately 7-minute drive",
    },
  ],
  [
    {
      id: 2,
      name: "Cambridge Hotel and Conference Centre",
      website: "https://www.cambridgehotel.ca/",
      address: "Address: 700 Hespeler Rd, Cambridge, ON N3H 5L8",
      distance: "Distance from Venue: 10km",
      drive: "Approximately 8-minute drive via 401",
    },
  ],
  [
    {
      id: 3,
      name: "Hilton Garden Inn Kitchener/Cambridge",
      website:
        "https://www.hilton.com/en/hotels/yyzcmgi-hilton-garden-inn-kitchener-cambridge/?SEO_id=GMB-AMER-GI-YYZCMGI&y_source=1_MjA4NDk0NS03MTUtbG9jYXRpb24ud2Vic2l0ZQ%3D%3D",
      address: "Address: 746 Old Hespeler Rd, Cambridge, ON N3H 5L8",
      distance: "Distance from Venue: 10km",
      drive: "Approximately 9-minute drive via 401",
    },
  ],
];

interface HotelProps {
  title: string;
  website: string;
  options: Array<{
    id: number;
    name: string;
    address: string;
    distance: string;
    drive: string;
  }>;
}

const Hotel = ({ title, website, options }: HotelProps) => {
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  return (
    <Grid
      templateColumns={{
        base: "1fr",
        md: "4fr 6fr 2fr",
      }}
      gap={{ base: 5, md: 8 }}
      alignItems="center"
      py={4}
    >
      <Heading size={"md"}>{title}</Heading>
      <List spacing={3} textAlign="start" ml={isMobile ? "" : "10"}>
        {options.map((hotel) => (
          <Box>
            {/* TODO: Resolve key issue  */}
            <ListItem>
              <ListIcon as={FaHotel} color="#b36125" />
              {hotel.address}
            </ListItem>
            <ListItem>
              <ListIcon as={GiPathDistance} color="#b36125" />
              {hotel.distance}
            </ListItem>
            <ListItem>
              <ListIcon as={GiSandsOfTime} color="#b36125" />
              {hotel.drive}
            </ListItem>
          </Box>
        ))}
      </List>
      <Stack>
        <Button
          color={"white"}
          rounded={"full"}
          px={6}
          bg={"#c7a48b"}
          _hover={{ bg: "#d6beab" }}
          right={isMobile ? "" : "30%"}
          onClick={() => window.open(website, "_blank")}
        >
          Website
        </Button>
      </Stack>
    </Grid>
  );
};

const Details = () => {
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const [isStandard] = useMediaQuery("(max-width: 2559px)");

  return (
    <Box>
      <Container maxW="3xl">
        <Divider
          width={isMobile ? "100%" : "100%"}
          mt="5%"
          mb="10px"
          bg="#b36125"
          padding="1px"
        />
        <Heading
          textAlign={"center"}
          size={isMobile ? "1xl" : isStandard ? "2xl" : "3xl"}
        >
          Location & Accomodations
        </Heading>
        <Divider
          width={isMobile ? "100%" : "100%"}
          mt="2%"
          mb="10px"
          bg="#b36125"
          padding="1px"
        />
      </Container>
      <VStack>
        <Box
          position="absolute"
          top={isMobile ? "7%" : isStandard ? "11%" : "14%"}
          left={isMobile ? "5%" : isStandard ? "15%" : "25%"}
          right="0"
          height={isMobile ? "10%" : "30%"}
          width={isMobile ? "90%" : isStandard ? "70%" : "50%"}
          backgroundImage="url(/files/Whistlebear.png)"
          backgroundSize="cover"
          backgroundPosition="center top"
          zIndex="0"
          _after={{
            content: '""',
            position: "absolute",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            backgroundColor: "rgba(0, 0, 0, 0.4)",
          }}
        />
        <Center>
          <Stack maxW={"xl"} spacing={6}>
            <Heading
              color={"white"}
              fontWeight={700}
              lineHeight={1.2}
              fontSize={isMobile ? "md" : "5xl"}
            >
              <Box
                position="relative"
                top={isMobile ? "105px" : isStandard ? "450" : "450"}
                left={isMobile ? "6px" : "-10%"}
                width={isMobile ? "95%" : "700px"}
                backdropFilter="blur(10px)"
                borderRadius="md"
                p={4}
                textAlign="center"
              >
                <Center>Whistle Bear Golf Club</Center>
              </Box>
            </Heading>
          </Stack>
        </Center>
      </VStack>
      <Container
        as={SimpleGrid}
        maxW={"7xl"}
        columns={{ base: 1, md: 2 }}
        spacing={{ base: 10, lg: "75px" }}
        py={{ base: 10, sm: 20, lg: 20 }}
        mt={"40%"}
      >
        <HStack>
          <Text as="h2" fontSize={isMobile ? "3xl" : "5xl"} noOfLines={4}>
            1316 Dickie Settlement Rd, Cambridge, ON N3H 4R8
          </Text>
          {!isMobile && (
            <Center height="375px">
              <Divider orientation="vertical" borderColor="black" size="15px" />
            </Center>
          )}
        </HStack>
        <AspectRatio ratio={16 / 9}>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2900.3644162962596!2d-80.39863488394344!3d43.369403779132014!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882c75960fa5e1db%3A0x6b284f48130ceeb9!2sWhistle%20Bear%20Golf%20Club!5e0!3m2!1sen!2sca!4v1674498039994!5m2!1sen!2sca" />
        </AspectRatio>
      </Container>

      <Box py={6} px={5} bg="white" color="black">
        <Stack spacing={4} width={"100%"} direction={"column"}>
          <Stack
            p={5}
            alignItems={"center"}
            justifyContent={{
              base: "flex-start",
              md: "space-around",
            }}
            direction={{
              base: "column",
              md: "row",
            }}
          >
            <Stack
              width={{
                base: "100%",
                md: "40%",
              }}
              textAlign={"center"}
            >
              <Heading size={"lg"}>
                Please see a list of nearby hotels below.
              </Heading>
            </Stack>
            <Stack
              width={{
                base: "100%",
                md: "60%",
              }}
            >
              <Text textAlign={"center"}>
                We hope everyone enjoys themselves, but also ask that you do not
                drink and drive. Please plan ahead and book a hotel room if you
                plan on drinking.
              </Text>
            </Stack>
          </Stack>
          {options.map((o, index) => (
            <Box>
              <Divider bg="#b36125" />
              <Hotel
                key={index}
                title={o[0]["name"]}
                website={o[0]["website"]}
                options={o}
              />
            </Box>
          ))}
          ;
        </Stack>
      </Box>
      <br />
      <br />
    </Box>
  );
};

export default Details;
