import { useState, useEffect } from 'react';
import { 
  Box, Image, Modal, ModalContent, ModalOverlay, useDisclosure, Text,
  Input, Button, FormControl, FormLabel, Flex, FormErrorMessage, Center, VStack
} from '@chakra-ui/react';
import Masonry from 'react-masonry-css';
import { useSwipeable } from 'react-swipeable'; 

const Photos = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedPhoto, setSelectedPhoto] = useState<string | null>(null);
  const [password, setPassword] = useState('');
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [passwordError, setPasswordError] = useState<string | null>(null);

  const openPhotoViewer = (photoPath) => {
    setSelectedPhoto(photoPath);
    onOpen();
  };

  const closePhotoViewer = () => {
    setSelectedPhoto(null);
    onClose();
  };

  const handlePasswordSubmit = () => {
    if (password === "N&M091523") {
      setAuthenticated(true);
    } else {
      setPasswordError('Sorry, this password is incorrect.');
    }
  };

  const breakpointColumnsObj = {
    default: 5,
    1100: 4,
    700: 3,
    500: 2
  };

  const photoPaths = Array.from({ length: 69 }, (_, i) => `/files/WilliamsPhotos/Photo${i + 1}.jpg`);
  const hilarysPhotos = Array.from({ length: 34 }, (_, i) => `/files/HilarysPhotos/Photo${i + 1}.jpg`);
  const brigidsPhotos = Array.from({ length: 8 }, (_, i) => `/files/BrigidsPhotos/Photo${i + 1}.jpg`);
  const allPhotos = [...photoPaths, ...hilarysPhotos, ...brigidsPhotos];

  useEffect(() => {
    const handleKeyDown = (e) => {
        if (!isOpen || !selectedPhoto) return;  // Ensure modal is open and a photo is selected

        const currentIndex = allPhotos.indexOf(selectedPhoto);
        if (e.key === 'ArrowRight') {
            const nextIndex = (currentIndex + 1) % allPhotos.length;
            setSelectedPhoto(allPhotos[nextIndex]);
        } else if (e.key === 'ArrowLeft') {
            const prevIndex = (currentIndex - 1 + allPhotos.length) % allPhotos.length;
            setSelectedPhoto(allPhotos[prevIndex]);
        }
    };

    window.addEventListener('keydown', handleKeyDown);

    // Clean up event listener on component unmount
    return () => {
        window.removeEventListener('keydown', handleKeyDown);
    };
}, [isOpen, selectedPhoto, allPhotos]);

const nextPhoto = () => {
  const allPhotos = [...photoPaths, ...hilarysPhotos, ...brigidsPhotos];
  const currentIndex = allPhotos.findIndex(photo => photo === selectedPhoto);
  if (currentIndex !== -1 && currentIndex < allPhotos.length - 1) {
      setSelectedPhoto(allPhotos[currentIndex + 1]);
  }
}

const previousPhoto = () => {
  const allPhotos = [...photoPaths, ...hilarysPhotos, ...brigidsPhotos];
  const currentIndex = allPhotos.findIndex(photo => photo === selectedPhoto);
  if (currentIndex > 0) {
      setSelectedPhoto(allPhotos[currentIndex - 1]);
  }
}

const handlers = useSwipeable({
  onSwipedLeft: () => nextPhoto(),
  onSwipedRight: () => previousPhoto(),
  onSwipedUp: () => closePhotoViewer(),
  trackMouse: true
});

  const masonryGridStyles = {
    display: "flex",
    marginLeft: "-15px",
    width: "auto"
  };

  const masonryGridColumnStyles = {
    paddingLeft: "15px"
  };

  const masonryGridItemStyles = {
    marginBottom: "15px"
  };

  return (
    <div {...handlers}>
      {isAuthenticated ? (
        <>
          <Box>
            <Masonry
              breakpointCols={breakpointColumnsObj}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column"
              style={masonryGridStyles}
            >
              {photoPaths.map((photoPath, index) => (
                <Box 
                  key={index} 
                  width="100%" 
                  style={masonryGridColumnStyles}
                >
                  <Box position="relative" cursor="pointer" style={masonryGridItemStyles}>
                    <Image
                      src={photoPath}
                      alt={`Photo ${index + 1}`}
                      onClick={() => openPhotoViewer(photoPath)}
                      objectFit="cover"
                      width="100%"
                    />
                  </Box>
                </Box>
              ))}
            </Masonry>

            <Text mt="10" mb="5" fontSize={"xl"}>
              And thank you to Hilary for capturing these beautiful moments<a href="https://instagram.com/littlegreenhouse_photography" target="_blank" style={{ color: '#98170a' }}> @littlegreenhouse_photography</a>
            </Text>

            <Masonry
              breakpointCols={breakpointColumnsObj}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column"
              style={masonryGridStyles}
            >
              {hilarysPhotos.map((photoPath, index) => (
                <Box 
                  key={index} 
                  width="100%" 
                  style={masonryGridColumnStyles}
                >
                  <Box position="relative" cursor="pointer" style={masonryGridItemStyles}>
                    <Image
                      src={photoPath}
                      alt={`Photo ${index + 1}`}
                      onClick={() => openPhotoViewer(photoPath)}
                      objectFit="cover"
                      width="100%"
                    />
                  </Box>
                </Box>
              ))}
            </Masonry>

            <Text mt="10" mb="5" fontSize={"xl"}>
              Thank you to Brigid for capturing these beautiful moments as well!<a href="https://instagram.com/daisyrose.photography" target="_blank" style={{ color: '#98170a' }}> @daisyrose.photography</a>
            </Text>

            <Masonry
              breakpointCols={breakpointColumnsObj}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column"
              style={masonryGridStyles}
            >
              {brigidsPhotos.map((photoPath, index) => (
                <Box 
                  key={index} 
                  width="100%" 
                  style={masonryGridColumnStyles}
                >
                  <Box position="relative" cursor="pointer" style={masonryGridItemStyles}>
                    <Image
                      src={photoPath}
                      alt={`Photo ${index + 1}`}
                      onClick={() => openPhotoViewer(photoPath)}
                      objectFit="cover"
                      width="100%"
                    />
                  </Box>
                </Box>
              ))}
            </Masonry>
          </Box>

          <Modal isOpen={isOpen} onClose={closePhotoViewer} size="3xl">
            <ModalOverlay />
            <ModalContent {...handlers}>
              {selectedPhoto && (
                <Image
                  src={selectedPhoto}
                  alt="Full-size Photo"
                  style={{ maxWidth: '100%', height: 'auto' }}
                />
              )}
            </ModalContent>
          </Modal>
        </>
      ) : (
        <Center>
          <Flex mt={5}>
          <FormControl isInvalid={Boolean(passwordError)}>
            <FormLabel>Password to view photos</FormLabel>
                <VStack spacing={4} alignItems="start">
                    <Input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Enter password"
                        width="250px"
                        bg="white"  // Set the background to white
                        isInvalid={Boolean(passwordError)}
                        errorBorderColor="red.300"
                    />
                    <FormErrorMessage>{passwordError}</FormErrorMessage>
                    <Button onClick={handlePasswordSubmit}>Submit</Button>
                </VStack>
            </FormControl>
          </Flex>
        </Center>
      )}
    </div>
  );
};

export default Photos;
